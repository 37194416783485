interface AlgoliaError {
  message: string
  name: string
  transporterStackTrace: any[]
}

export default function useErrorHandling() {
  const alerts = ref<Alert[]>([])

  function isAlgoliaError(err: unknown): err is AlgoliaError {
    return (
      typeof err === "object" &&
      err !== null &&
      ["name", "message", "transporterStackTrace"].every((key) => key in err)
    )
  }

  const handleError = (error: unknown) => {
    if (isAlgoliaError(error)) {
      alerts.value.push({
        message:
          // eslint-disable-next-line max-len
          "We are currently encountering some technical difficulties with this section of the website. A simple refresh should help resolve the issue. If refreshing doesn't resolve the matter, we kindly ask for your patience as our team works diligently to rectify the problem. Please check back later, and we apologize for any inconvenience this may have caused.",
        type: "error",
      })
      throw error
    }
  }

  return {
    alerts,
    handleError,
  }
}
